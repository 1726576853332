import { template as template_06a91a71f07545279895261edcf6fb33 } from "@ember/template-compiler";
const FKControlMenuContainer = template_06a91a71f07545279895261edcf6fb33(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
