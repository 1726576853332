import { template as template_cca44010553740db8352254ec67cc71d } from "@ember/template-compiler";
const SidebarSectionMessage = template_cca44010553740db8352254ec67cc71d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
